<template>
  <div class="main-div">
    <div class="vasion-page-title top-div">
      {{ headerText }}
    </div>
    <div class="md-layout top-div">
      <div class="text-div">
        <VasionInput
          v-model="tokenText"
          class="input-style"
          inputType="top-white"
          :placeholder="'Enter Token...'"
          :max-length-prop="100"
        />
      </div>
      <div class="add-button-div">
        <VasionButton
          id="add-token-button"
          :icon="'VasionAddIcon'"
          title="Add Token"
          @vasionButtonClicked="addTokenClick()"
        />
      </div>
    </div>
    <div class="md-layout top-div">
      <VasionDropList
        v-slot="slotItem"
        v-model="selectedForm"
        :dataArray="indexForms"
        :width="370"
        title="OBJECT"
        inputType="plain-list"
        valueName="id"
        displayName="name"
        class="attribute-droplist pad-right"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
      <VasionDropList
        v-slot="slotItem"
        v-model="selectedField"
        :dataArray="indexFieldsForForm"
        :width="370"
        title="FIELD"
        inputType="plain-list"
        valueName="fieldId"
        displayName="fieldTitle"
        class="attribute-droplist"
      >
        {{ slotItem.item.fieldTitle }}
      </VasionDropList>
    </div>
    <div v-if="localItems && localItems.length > 0" class="item-div">
      <label class="vasion-page-subheader">Current Tokens:</label>
      <ul id="selected-items-ul" class="vasion-styled-ul fit-ul">
        <li v-for="item in localItems" :key="item.token + item.formId + item.fieldId">
          <div class="vasion-styled-li-div" title="Remove Token">
            <table>
              <tr>
                <td @click="removeItem(item)">
                  {{ item.token }} {{ item.formName ? "- " + item.formName + "." + item.fieldTitle : "" }}
                </td>
                <td class="alignRight">
                  <VasionRemoveCircleIcon @click="removeItem(item)" />
                </td>
              </tr>
            </table>
          </div>
          <div class="vasion-li-vertical-spacer" />
        </li>
      </ul>
    </div>
    <div class="bottom-div">
      <VasionButton id="cancel-button" :classProp="'primary-light'" @vasionButtonClicked="cancelClick()">
        CANCEL
      </VasionButton>
      <VasionButton id="ok-button" :classProp="'primary'" @vasionButtonClicked="okClick()">
        OK
      </VasionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfigureTokens',
  props: {
    headerText: {
      type: String,
      default: '',
      required: false,
    },
    itemsArray: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data: function () {
    return {
      localItems: [],
      tokenText: '',
      tokensList: [],
      indexForms: [],
      selectedForm: null,
      indexFieldsForForm: [],
      selectedField: null,
    }
  },
  computed: { },
  watch: {
    selectedForm: function () {
      this.selectedField = ""
      this.indexFieldsForForm = this.selectedForm && this.selectedForm.fields ? this.selectedForm.fields : [{fieldTitle: "", fieldId: 0}]
    },
  },
  async created() {
    this.localItems = this.itemsArray.slice(0)
    const data = await this.$store.dispatch('security/getConfigurableTokens')
    this.tokensList = data.fields
    this.indexForms.push({name: '', id: 0})
    if (this.tokensList && this.tokensList.length > 0) {
      this.tokensList.forEach(val => this.indexForms.push(val))
    }
    this.indexFieldsForForm = [{fieldTitle: "", fieldId: 0}]
  },
  methods: {
    addTokenClick() {
      if (this.tokenText && (!this.selectedForm || (this.selectedForm && this.selectedField))) {
        const foundItem = this.localItems.find((element) => {
          return element.token.toLowerCase() === this.tokenText.toLowerCase() && (
              (element.formId === 0 && this.selectedForm === null) 
              || (element.formId === this.selectedForm.id && element.fieldId === this.selectedField.fieldId)
            )
        })

        if (!foundItem) {
          this.localItems.push({
            token: this.tokenText, 
            formId: this.selectedField ? this.selectedField.formId : 0, 
            formName: this.selectedField ? this.selectedField.formName : null,
            fieldId: this.selectedField ? this.selectedField.fieldId : 0,
            fieldTitle: this.selectedField ? this.selectedField.fieldTitle : null,
            })
          this.tokenText = ''
          this.selectedForm = null
        } else {
          this.$emit('error', 'A duplicate token already exists.')
        }

      }
    },
    cancelClick() {
      this.$emit('cancel-click')
    },
    okClick() {
      this.$emit('ok-click', this.localItems)
    },
    removeItem(item) {
      if (item) {
        this.localItems = this.localItems.filter((element) => {
          return item !== element
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .fit-ul {
    width: calc(100% - 56px);
  }

  table {
    width: 100%;
  }

  .pad-right {
    padding-right: 20px;
  }

  .add-button-div {
    width: 50px;
    text-align: center;
  }

  .text-div {
    width: calc(100% - 98px);
  }

  .main-div {
    width: 100%;
    height: 100%;
    margin: 32px 24px 32px 24px;
  }

  .top-div {
    height: 50px;
  }

  .item-div {
    position: absolute;
    top: 220px;
    height: calc(100% - 260px);
    width: 100%;
  }

  .bottom-div {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
  }

  .alignRight {
    text-align: right;
  }

</style>
