<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Group Management</h1>
      <div class="btns-container">
        <VasionButton
          v-if="canConfigureGroups"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="editGroupClick(0)"
        >
          New Group
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="md-layout search-row">
        <VasionInput
          id="vasion-droplist-search"
          v-model="GroupSearchInput"
          v-debounce:300ms="groupSearchList"
          name="vasion-droplist-search"
          class="search-field"
          placeholder="Search Groups..."
          :width="'250'"
          inputType="search-white"
        />
        <md-menu
          v-if="canExportUsers"
          md-align-trigger
          :mdCloseOnSelect="true"
          :mdCloseOnClick="true"
        >
          <md-button
            id="btn-export-groups-list"
            md-menu-trigger
            class="v-btn-primary ml md-button-export"
            :disabled="false"
          >
            Export
            <VasionArrowDropDownIcon />
          </md-button>
          <md-menu-content class="dropdown-list-container">
            <md-menu-item @click="exportUsersList('CSV')">
              CSV
            </md-menu-item>
            <md-menu-item @click="exportUsersList('PDF')">
              PDF
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>
      <div v-if="groupsLoading">
        <!-- Show white space while waiting for data to load -->
      </div>
      <div v-else-if="localGroupList && tableData.Columns" class="groups-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          :supportSorting="true"
          @vasion-column-sort="sortColumns"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
    </div>
    <md-dialog id="apps-dialog" :md-active.sync="showAppsDialog" :md-click-outside-to-close="false">
      <div class="dialog-main-div">
        <div class="vasion-page-title top-div">
          <label>Assign Apps to {{ groupName }}</label>
        </div>
        <div class="field-selection-div">
          <VasionListSelection
            :available-fields.sync="availableApps"
            :selected-fields.sync="assignedApps"
            :allow-field-ordering="allowRoleOrdering"
            :available-label-text="'Defined Apps'"
            :selected-label-text="'Assigned Apps'"
            :unique-id="`list-of-${groupName}`"
          />
        </div>
        <div class="bottom-div">
          {{ appErrorText }}
          <VasionButton id="cancel-apps-button" :classProp="'primary-light'" @vasionButtonClicked="showAppsDialog = false">
            CANCEL
          </VasionButton>
          <VasionButton id="ok-apps-button" :classProp="'primary'" @vasionButtonClicked="okAppsClick()">
            OK
          </VasionButton>
        </div>
      </div>
    </md-dialog>
    <md-dialog id="roles-dialog" :md-active.sync="showRolesDialog" :md-click-outside-to-close="false">
      <div class="dialog-main-div">
        <div class="vasion-page-title top-div">
          <label>Assign Roles to {{ groupName }}</label>
        </div>
        <div class="field-selection-div">
          <VasionListSelection
            :available-fields.sync="availableRoles"
            :selected-fields.sync="assignedRoles"
            :allow-field-ordering="allowRoleOrdering"
            :available-label-text="'Defined Roles'"
            :selected-label-text="'Assigned Roles'"
            :unique-id="`roles-to-${groupName}`"
          />
        </div>
        <div class="bottom-div">
          {{ roleErrorText }}
          <VasionButton id="cancel-roles-button" :classProp="'primary-light'" @vasionButtonClicked="showRolesDialog = false">
            CANCEL
          </VasionButton>
          <VasionButton id="ok-roles-button" :classProp="'primary'" @vasionButtonClicked="okRolesClick()">
            OK
          </VasionButton>
        </div>
      </div>
    </md-dialog>
    <md-dialog id="entities-dialog" :md-active.sync="showEntitiesDialog" :md-click-outside-to-close="false">
      <div class="dialog-main-div">
        <div class="vasion-page-title top-div">
          <label>{{ assignEntitiesHeader }}</label>
        </div>
        <div class="field-selection-div">
          <VasionListSelection
            :available-fields.sync="availableEntities"
            :selected-fields.sync="assignedEntities"
            :allow-field-ordering="allowRoleOrdering"
            :available-label-text="availableEntitiesLabel"
            :selected-label-text="assignedEntitiesLabel"
            :unique-id="`list-of-${assignEntitiesHeader}`"
          />
        </div>
        <div class="bottom-div">
          {{ entityErrorText }}
          <VasionButton id="cancel-entities-button" :classProp="'primary-light'" @vasionButtonClicked="cancelEntitiesClick()">
            CANCEL
          </VasionButton>
          <VasionButton id="ok-entities-button" :classProp="'primary'" @vasionButtonClicked="okEntitiesClick()">
            OK
          </VasionButton>
        </div>
      </div>
    </md-dialog>
    <md-dialog id="tokens-dialog" :md-active.sync="showTokenDialog" :md-click-outside-to-close="false">
      <ConfigureTokens
        :header-text="tokenHeaderText"
        :items-array="tokens"
        @ok-click="okTokenClick"
        @cancel-click="cancelTokenClick"
        @error="tokenError"
      />
    </md-dialog>
    <VasionGeneralModal
      id="configure-group-dialog"
      modalType="slot"
      :modalTitle="configureGroupTitle"
      :sync="showConfigureGroupDialog"
      :hideButtons="true"
      showTopRightCloseButton
      @noButtonClick="cancelConfigureGroupClick"
    >
      <ConfigureGroup
        :modify-group="selectedGroup"
        @ok-click="okConfigureGroupClick"
        @cancel-click="cancelConfigureGroupClick"
      />
    </VasionGeneralModal>
    <md-dialog id="confirm-group-delete" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="toggleDeleteDialog()" @yesButtonClick="deleteGroupAndToggle()" />
    </md-dialog>
    <VasionSnackbar
      id="group-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import ConfigureGroup from '@/components/security/ConfigureGroup.vue'
import ConfigureTokens from '@/components/security/ConfigureTokens.vue'
import { tableSorter } from '@/store/helperModules/common.module'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'Groups',
  components: {
    ConfigureGroup,
    ConfigureTokens,
    Loading,
  },
  data: function () {
    return {
      allowRoleOrdering: false,
      appErrorText: '',
      assignedApps: [],
      assignedEntities: [],
      assignedRoles: [],
      availableApps: [],
      availableEntities: [],
      availableRoles: [],
      columns: {
        Values: [
          '_GroupID_',
          'Name',
          'Description',
          'Dynamic',
          'Active',
          '_VasionEllipsisButton_',
        ],
        ValueType: 'String',
      },
      currentUser: null,
      entityErrorText: '',
      groupID: 0,
      groupName: '',
      groupsLoading: true,
      GroupSearchInput: '',
      hiddenColumns: ['_GroupID_'],
      isLoading: true,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      localGroupList: null,
      roleErrorText: '',
      selectedGroup: {},
      showAppsDialog: false,
      showConfigureGroupDialog: false,
      showDeleteDialog: false,
      showEntitiesDialog: false,
      showRolesDialog: false,
      showSnackbar: false,
      showTokenDialog: false,
      snackbarImage: true,
      snackbarSubTitle: '',
      snackbarTitle: '',
      sortTableAscending: true,
      sortTableBy: 'Name',
      tableData: {},
      tokens: [],
    }
  },
  computed: {
    assignedEntitiesLabel() {
      let ret = ''
      if (this.selectedGroup) {
        ret = this.selectedGroup.Dynamic ? 'Assigned Groups' : 'Assigned Users'
      }

      return ret
    },
    assignEntitiesHeader() {
      let ret = ''
      if (this.selectedGroup) {
        ret = this.selectedGroup.Dynamic ? `Assign Groups to ${this.selectedGroup.Name}` : `Assign Users to ${this.selectedGroup.Name}`
      }

      return ret
    },
    availableEntitiesLabel() {
      let ret = ''
      if (this.selectedGroup) {
        ret = this.selectedGroup.Dynamic ? 'Available Groups' : 'Available Users'
      }

      return ret
    },
    canConfigureGroups() { return this.$store.getters['common/canConfigureGroups'] },
    canConfigurSecurityRoles() { return this.$store.getters['common/canConfigurSecurityRoles'] },
    canExportUsers() {return this.$store.getters['common/isAdminUser'] || this.currentUserIsDepartmentAdmin },
    configureGroupTitle() { return this.selectedGroup ? 'Edit Group' : 'Create Group' },
    currentUserIsDepartmentAdmin() { return this.currentUser?.adminForDepartments?.length > 0 && !this.currentUser?.admin },
    deletePromptMessage() { return `Are you sure you want to delete "${this.groupName}"?` },
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    groupList() { return this.$store.state.security.groupList },
    tokenHeaderText() { return `Token Security - ${this.groupName}` },
  },
  async created() {
    const promise1 = this.$store.dispatch('security/loadGroupList')
    const promise2 = this.$store.dispatch('security/setEllipsisButtonConfig', 'group-buttons')
    await Promise.all([promise1, promise2])
    this.currentUser = await this.$store.dispatch('security/getUserDetails', this.$store.state.common.userID)

    this.groupsLoading = false
    await this.refreshTable()
  },
  methods: {
    async assignAppsClick(groupID, groupName) {
      this.groupID = groupID
      this.groupName = groupName
      this.roleErrorText = ''

      this.assignedApps = []
      this.availableApps = []
      const [groupCurrentApps, allApps] = await Promise.all([
        this.$store.dispatch('security/getGroupApps', this.groupID),
        this.$store.dispatch('security/getAllApps'),
      ])
      const remainingApps = allApps.Values.filter(app => groupCurrentApps.Values.findIndex(value => value.systemName === app.systemName) === -1)
      if (groupCurrentApps) {
        if (groupCurrentApps.Values) {
          groupCurrentApps.Values.forEach((element) => {
            this.assignedApps.push({
              value: element.systemName,
              name: element.displayName,
            })
          })
        }

        if (remainingApps) {
          remainingApps.forEach((element) => {
            this.availableApps.push({
              value: element.systemName,
              name: element.displayName,
            })
          })
        }
      }

      this.showAppsDialog = true
    },
    async assignEntitiesClick(groupID, groupName) {
      this.groupID = groupID
      this.groupName = groupName
      this.entityErrorText = ''

      this.assignedEntities = []
      this.availableEntities = []

      this.selectedGroup = this.groupList.find((group) => {
        return group.ID === groupID
      })

      if (!this.selectedGroup) {
        return
      }

      if (this.selectedGroup.Dynamic) {
        // it's a Dynamic Group, so it contains non-dynamic child groups
        this.groupList.forEach((group) => {
          if (!group.Dynamic && group.ID !== this.groupID) {
            this.availableEntities.push({
              value: group.ID,
              name: group.Name,
            })
          }
        })
      } else {
        // it's a normal group, so it contains users
        const enabledUserList = await this.$store.dispatch('security/getUserListEnabledOnly')
        if (enabledUserList) {
          enabledUserList.forEach((user) => {
            this.availableEntities.push({
              value: user.UserId,
              name: `${user.Username} - ${user.FullName}`,
            })
          })
        }
      }

      // Build the list of assigned entities, as trim down the available entities
      const childrenResult = await this.$store.dispatch('security/getGroupChildren', this.groupID)
      if (childrenResult) {
        childrenResult.forEach((child) => {
          this.assignedEntities.push({
            value: child.iID,
            name: child.sName,
          })

          this.availableEntities = this.availableEntities.filter((element) => {
            return element.value !== child.iID
          })
        })
      }

      this.showEntitiesDialog = true
    },
    async assignRolesClick(groupID, groupName) {
      this.groupID = groupID
      this.groupName = groupName
      this.roleErrorText = ''

      this.assignedRoles = []
      this.availableRoles = []

      const rolesResult = await this.$store.dispatch('security/getGroupRoles', this.groupID)
      if (rolesResult) {
        if (rolesResult.assignedRoles) {
          rolesResult.assignedRoles.forEach((element) => {
            this.assignedRoles.push({
              value: element.iID,
              name: element.sName,
            })
          })
        }

        if (rolesResult.availableRoles) {
          rolesResult.availableRoles.forEach((element) => {
            this.availableRoles.push({
              value: element.iID,
              name: element.sName,
            })
          })
        }
      }

      this.showRolesDialog = true
    },
    cancelConfigureGroupClick() { this.showConfigureGroupDialog = false },
    cancelEntitiesClick() { this.showEntitiesDialog = false },
    cancelRolesClick() { this.showRolesDialog = false },
    cancelTokenClick() { this.showTokenDialog = false },
    async deleteGroupAndToggle() {
      this.toggleDeleteDialog()

      if (this.groupID > 0) {
        const result = await this.$store.dispatch('security/deleteGroup', this.groupID)
        if (result && result.Value === 'True') {
          this.isLoading = true
          this.$store.dispatch('security/loadGroupList')
          this.snackbarTitle = 'Group Successfully Deleted'
          this.snackbarSubTitle = `Deleted group "${this.groupName}"`
          this.snackbarImage = true
          this.showSnackbar = true
          await this.refreshTable()
        } else {
          this.snackbarTitle = 'Group Not Deleted'
          this.snackbarSubTitle = `Unable to delete group "${this.groupName}"`
          this.snackbarImage = false
          this.showSnackbar = true
        }
      }

      this.groupID = 0
      this.groupName = ''
    },
    deleteGroupClick(groupID, groupName) {
      this.groupID = groupID
      this.groupName = groupName
      this.toggleDeleteDialog()
    },
    editGroupClick(groupID) {
      this.selectedGroup = this.groupList.find((group) => {
        return group.ID === groupID
      })

      this.showConfigureGroupDialog = true
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const groupId = payload.item.Values[0]
        const group = this.localGroupList.find(g => g.ID === groupId)

        switch (payload.buttonId) {
          case 'assign-app-security-button':
            this.assignAppsClick(group.ID, group.Name)
          break;
          case 'edit-group-button':
            this.editGroupClick(group.ID)
            break;
          case 'delete-group-button':
            this.deleteGroupClick(group.ID, group.Name)
            break;
          case 'assign-users-button':
            this.assignEntitiesClick(group.ID, group.Name)
            break;
          case 'assign-roles-button':
            this.assignRolesClick(group.ID, group.Name)
            break;
          case 'token-security-button':
            this.tokenSecurityClick(group.ID, group.Name)
            break;
          default:
            break;
        }
      }
    },
    exportUsersList(fileType) {
      const payload = {
        "reportType": 'GROUP',
        "outputFormat": fileType
      }
      
      this.$store.dispatch('security/downloadUsersList', payload)
    },
    async filteredValues(groups, text) {
      const input = text.toLowerCase()
      if (!input) { return groups }
      const ret = groups.filter(group => {
        const nameTarget = group.Name.toLowerCase()
        const foundName = nameTarget.indexOf(input) > -1

        const descriptionTarget = group.Description.toLowerCase()
        const foundDescription = descriptionTarget.indexOf(input) > -1

        return foundName || foundDescription
      })

      return ret
    },
    async groupSearchList(input) {
      this.localGroupList = this.groupList
      if (input) {
        this.localGroupList = await this.filteredValues(this.groupList, input)
      }

      this.reloadTable()
    },
    hideSnackbar() {
      this.snackbarText = ''
      this.showSnackbar = false
    },
    async okAppsClick() {
      this.roleErrorText = ''

      const rolePayload = {
        entityID: this.groupID,
        appSystemNames: this.assignedApps ? this.assignedApps.map((role) => role.value) : [],
      }

      const result = await this.$store.dispatch('security/saveGroupApps', rolePayload)

      if (result && result === 'True') {
        this.showAppsDialog = false
      } else {
        this.roleErrorText = 'Unable to Save Group Apps'
      }
    },
    async okConfigureGroupClick() {
      this.showConfigureGroupDialog = false
      this.isLoading = true
      await this.$store.dispatch('security/loadGroupList')

      this.showSnackbar = true
      this.snackbarImage = true
      this.snackbarTitle = 'Success'
      this.snackbarSubTitle = 'Group was successfully saved.'

      if (this.GroupSearchInput) {
        this.groupSearchList(this.GroupSearchInput)
      } else {
        this.refreshTable()
      }
    },
    async okEntitiesClick() {
      this.entityErrorText = ''

      const entityPayload = {
        groupID: this.groupID,
        childIDs: this.assignedEntities.map((element) => {
          return element.value
        }),
        supervisorIDs: [],
      }

      const result = await this.$store.dispatch('security/saveGroupChildren', entityPayload)

      if (result && result.Value && result.Value === 'True') {
        this.showEntitiesDialog = false
      } else {
        this.entityErrorText = 'Unable to save Group Children'
      }
    },
    async okRolesClick() {
      this.roleErrorText = ''

      const rolePayload = {
        entityID: this.groupID,
        roleIDs: this.assignedRoles ? this.assignedRoles.map((role) => role.value) : [],
      }

      const result = await this.$store.dispatch('security/saveGroupRoles', rolePayload)

      if (result && result === 'True') {
        this.showRolesDialog = false
      } else {
        this.roleErrorText = 'Unable to save Group Roles'
      }
    },
    async okTokenClick(selectedTokens) {
      const saveTokensPayload = {
        entityID: this.groupID,
        tokens: selectedTokens,
      }

      const saveResult = await this.$store.dispatch('security/saveGroupTokens', saveTokensPayload)

      if (saveResult && saveResult === 'True') {
        this.showSnackbar = true
        this.snackbarImage = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = `Group Tokens for '${this.groupName}' were successfully saved.`

        this.showTokenDialog = false
      } else {
        this.showSnackbar = true
        this.snackbarImage = false
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Unable to save Group Tokens.'
      }
    },
    async refreshTable() {
      this.localGroupList = this.groupList
      const data = {
        dataList: this.localGroupList,
        columns: this.columns,
      }
      this.tableData = await this.$store.dispatch('security/setGroupTableData', data)
      this.isLoading = false
    },
    async reloadTable() {
      const data = {
        dataList: this.localGroupList,
        columns: this.columns,
      }
      this.tableData = await this.$store.dispatch('security/setGroupTableData', data)
      this.isLoading = false
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      const groupId = data.Values[0]
      const group = this.localGroupList.find(g => g.ID === groupId)
      this.editGroupClick(group.ID)
    },
    async sortColumns(options) {
      this.isLoading = true
      this.sortTableAscending = options.ascending
      switch (options.columnName) {
        case 'Name':
          this.sortTableBy = 'Name'
          break
        case 'Description':
          this.sortTableBy = 'Description'
          break
        case 'Dynamic':
          this.sortTableBy = 'Dynamic'
          break
        case 'Active':
          this.sortTableBy = 'Active'
          break
        default:
          break
      }
      this.localGroupList.sort(tableSorter(this.sortTableBy, this.sortTableAscending))
      this.refreshTable()
    },
    toggleDeleteDialog() { this.showDeleteDialog = !this.showDeleteDialog },
    tokenError(message) {
      this.showSnackbar = true
      this.snackbarImage = false
      this.snackbarTitle = 'Error'
      if (message) {
        this.snackbarSubTitle = message
      } else {
        this.snackbarSubTitle = "Error configuring Token Security."
      }
    },
    async tokenSecurityClick(groupID, groupName) {
      this.groupID = groupID
      this.groupName = groupName
      this.tokens = []

      const tokensResult = await this.$store.dispatch('security/getGroupTokens', this.groupID)
      if (tokensResult && tokensResult.fields) {
        tokensResult.fields.forEach((element) => {
          this.tokens.push(element)
        })
      }

      this.showTokenDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/btn-styles.scss';

  #roles-dialog, #entities-dialog, #apps-dialog {
    width: 60%;
    height: 70%;
    min-width: 1000px;
  }

  #tokens-dialog {
    height: 500px;
    width: 800px;
  }

  .grid-div {
    height: calc(100vh - 284px);
    overflow: auto;
  }

  .dialog-main-div {
    width: 100%;
    height: 100%;
    margin: 32px 24px 32px 24px;
  }

  .top-div {
    height: 50px;
  }

  .bottom-div {
    position: absolute;
    bottom: 24px;
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
  }

  .search-row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .dropdown-list-container {
    margin-top: 4px;
  }

  .dropdown-list-container,
  .dropdown-list-container ::v-deep .md-menu-content-container {
    border-radius: 8px;
  }

  .dropdown-list-container ::v-deep .md-list {
    padding: 0px;
  }

  .dropdown-list-container ::v-deep button.md-list-item-button:hover {
    background-color: rgba(255, 81, 0, 0.08) !important;
  }
  .groups-table-container {
    height: calc(100vh - 284px);
    overflow: auto;
    text-align: left;
  }

  .md-button-export {
    padding-top: 2px;
    padding-left: 10px;
  }
</style>
