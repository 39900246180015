<template>
  <div class="main-div">
    <div class="form-div">
      <VasionInput
        v-model="name"
        title="Name"
        class="input-style"
        inputType="top-white"
        required
        :isInErrorState="formFieldErrors.name"
        @input="val => val ? clearFieldError('name') : null"
      />

      <VasionInput
        v-model="description"
        title="Description"
        class="input-style"
        inputType="top-white"
      />

      <div class="md-layout">
        <div class="checkbox-div">
          <VasionCheckbox
            id="dynamic-group"
            name="dynamic-group"
            :checked="dynamicGroup"
            @change="toggleDynamicGroup()"
          >
            Dynamic Group
          </VasionCheckbox>
        </div>
        <div class="checkbox-div">
          <VasionCheckbox
            id="active-group"
            name="active-group"
            :checked="active"
            @change="toggleActiveGroup()"
          >
            Active
          </VasionCheckbox>
        </div>
      </div>

      <VasionInput
        v-model="ldapGroupName"
        title="LDAP Group"
        class="input-style"
        inputType="top-white"
      />

      <div class="bottom-div">
        <VasionButton id="cancel-button" :classProp="'primary-light'" @vasionButtonClicked="cancelClick()">
          CANCEL
        </VasionButton>
        <VasionButton id="ok-button" :classProp="'primary'" @vasionButtonClicked="okClick()">
          OK
        </VasionButton>
      </div>
    </div>

    <VasionSnackbar
      :showSnackbarBool.sync="snackbar.show"
      :snackbarImage="snackbar.image"
      :snackbarSubTitle="snackbar.subtitle"
      :snackbarTitle="snackbar.title"
    />
  </div>
</template>

<script>
export default {
  name: 'ConfigureGroup',
  props: {
    modifyGroup: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data: function () {
    return {
      active: true,
      description: '',
      dynamicGroup: false,
      formFieldErrors: {
        name: false,
      },
      ldapGroupName: '',
      name: '',
      snackbar: {
        show: false,
        image: false,
        subtitle: '',
        title: ''
      }
    }
  },
  computed: {
    groupID() { return !this.modifyGroup || !this.modifyGroup.ID ? 0 : this.modifyGroup.ID },
    headerText() { return this.groupID > 0 ? 'Edit Group' : 'Create Group' },
  },
  created: function () {
    this.errorText = ''

    if (this.groupID <= 0) {
      return
    }

    this.active = this.modifyGroup.Active
    this.description = this.modifyGroup.Description
    this.dynamicGroup = this.modifyGroup.Dynamic
    this.ldapGroupName = this.modifyGroup.LDAPName
    this.name = this.modifyGroup.Name
  },
  methods: {
    cancelClick() {
      this.$emit('cancel-click')
    },
    clearFieldError(field) {
      this.formFieldErrors[field] = false
    },
    clearFieldErrors() {
      Object.keys(this.formFieldErrors).map(key => this.formFieldErrors[key] = false)
    },
    async okClick() {
      this.clearFieldErrors()
      let errorText = ''

      if (!this.name) {
        this.formFieldErrors.name = true
      } else if (this.name.trim() === '') {
        errorText += 'Group Name cannot only include spaces.'
      }

      if (Object.values(this.formFieldErrors).some(x => x)) {
        errorText = 'Please fill out all required fields.'
      }

      if (errorText) {
        this.snackbar = {
          show: true,
          image: false,
          title: 'Error',
          subtitle: errorText,
        }
        return
      }

      const savePayload = {
        Name: this.name,
        ID: this.groupID,
        LDAPName: this.ldapGroupName,
        Description: this.description,
        Active: this.active,
        Deleted: !this.active,
        Dynamic: this.dynamicGroup,
      }

      const saveResult = await this.$store.dispatch('security/saveGroupDetails', savePayload)
      if (!saveResult || !saveResult.ID) {
        this.snackbar = {
          show: true,
          image: false,
          title: 'Error',
          subtitle: 'Unable to save the Group.'
        }
        return
      }

      this.$emit('ok-click')
    },
    toggleActiveGroup() {
      this.active = !this.active
    },
    toggleDynamicGroup() {
      this.dynamicGroup = !this.dynamicGroup
    },
  },
}
</script>

<style lang="scss" scoped>
  .main-div {
    width: 480px;
    padding-top: 24px;
  }

  .form-div {
    display: flex;
    flex-direction: column;
  }

  .bottom-div {
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
    margin-top: 12px;
  }

  .checkbox-div {
    margin: 12px 0px;
    padding-right: 10px;
  }

  .input-style {
    margin-top: 5px;
    margin-bottom: 5px;
  }
</style>
